import styled from "styled-components"

export const CardboxHolder = styled.div`

.container {
    .card-group {
        margin-top: -200px;

        .card {
            border: none;
            border-radius: 2px;
            width: 80%;
            height: 20vh;
            margin: 35px auto;

            @media screen and (min-width: 768px) {
            width: auto;
            height: auto;
            margin: 5px;

            }

            &:first-child {
                @media screen and (min-width: 768px) {
                margin-left: 0;
                }
            }
            &:last-child {
                @media screen and (min-width: 768px) {
                margin-right: 0;
                }
            }
            .card-img-holder {
                height: 150px;
                display: flex;
                justify-content: center;
                align-items: center;
                display: none;
                .card-img-top {
                    width: 100px;
                    opacity: .9;
                }
            }
            
            .card-body {
                box-shadow: 2px 2px 5px #555;
                padding: .75em;
                .card-title {
                    font-size: 1.1rem;
                }
                p {
                    font-size: .8rem;
                }
            }
            .card-footer {
                padding: 0;
                a {
                    background: ${props => props.theme.colors.prime};
                    color: white;
                    width: 100%;
                    height: 100%;
                    display: block;
                    text-align: center;
                    font-family: 'Ubuntu', sans-serif !important;
                    transition:.3s;

                    &:hover {
                        background: #3f444f;
                        color: white;
                        text-decoration:none;
                    }
                }
            }
        }
    }
}
`
