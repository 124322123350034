import styled from "styled-components"

export const TwoBoxHolder = styled.div`
display:flex;
flex-direction:column; 
width:100%;
background: white;
margin: 0 auto;
position:relative;
z-index: 1;

&.gray-bg {
    background: #f2f2f2 !important; 
}

    > div {
        width: 100%;
        text-align: left;
        padding: 15px;
        margin: 0 auto; 

        h2 {
            margin-bottom:35px; 
            position:relative;
            font-size:1.5rem;
            :before {
                content:"";
                height:2px; 
                width:80%;
                left:0%;
                background:${props => props.theme.colors.prime};;
                position: absolute;
                bottom: -5px;
            }
        }
        p {
            font-size:1.2rem;
            line-height:1.5;
        }

        img {
            width: 100%;
        }
    }

@media screen and (min-width: 768px) {

    flex-direction:row;
    padding: 70px 35px;

    > div {
        width:100%;

        div {
            width: 50%;
        }

        .rightbox {
            text-align: center;

            img {
                max-width: 400px;
                width:100%;
            }
        }
        
        h2 {
            font-size: 2rem;
            color:#ad1d1d;
            :before {
                
                width:50%;
                left:0%;
                
            }
        }
        p {
            font-size:.9rem;
            line-height:1.5;
        }
    }
}
`
export const Contianer = styled.div`
  max-width: 1140px;
  margin: 0 auto;

  @media screen and (min-width: 768px) {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  `