import React from 'react'

import { TwoBoxHolder, Contianer } from "./Twoboxes.styles" 

const Twobox = (props) => (  

 
    <TwoBoxHolder className={props.Class}> 
        <Contianer>
            <div className="leftbox">
            <h2>{props.Header}</h2>
                <p> {props.TextBlock} </p>
                
            </div>
            <div className="rightbox">
                <img src={props.Bild} />
            </div>
        </Contianer> 
    </TwoBoxHolder>
)

export default Twobox