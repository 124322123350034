import React from 'react'
import { Link } from 'gatsby'
import { FaHome, FaBuilding, FaTruck, FaLongArrowAltRight } from "react-icons/fa"; 

// Hooks
import { useHyrbilDataQuery } from 'Hooks/useHyrbilDataQuery'
// Styles
import { CardHolder } from "./Cards.styles"

const Card = () => {
    const hyrbilQuery = useHyrbilDataQuery()
    
return(
        <CardHolder>
            <h2>Populära tjänster</h2>
            <ul>
                {hyrbilQuery.hyrbil.map(item => ( 
                  <li>
                    <Link to={item.link} key={item.id}>
                        {/* <Image className="img-hyr" fluid={item.bild.chilImageSharp.fluid} alt={item.title} /> */}
                       {/* <img width="100" height="100" src={item.bild.childImageSharp.fluid.src} alt={item.title}/> */}
                        <div className="card-info-holder">
                            <div> <span>{item.fa === 'ett' && 
                              <>
                                <FaTruck />
                              </>
                              } 
                              {item.fa === 'två' && 
                              <>
                                <FaBuilding />
                              </>
                              } 
                              {item.fa === 'tre' && 
                              <>
                                <FaHome />
                              </>
                              } </span>
                              <h3>{item.name}</h3>
                              </div>
                            <h4>{item.h4}</h4>
                            <p>{item.excerpt}</p>
                            <Link to={item.link} >Läs mer om tjänsten<FaLongArrowAltRight /></Link> 
                        </div>
                 </Link>
                 </li>
                ))}
            </ul>
        </CardHolder>
    )
}

export default Card
