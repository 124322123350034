import styled from "styled-components"

export const CardHolder = styled.div`
background: #fff;
width: 100%;
padding:0;
padding-top: 35px;
padding-bottom: 35px;

h2 {
    color:#222;
    font-size:1.5rem;
    text-align:center;
    margin:0;
    margin-bottom:35px;
}
p {
    width:90%;
    margin: 0 auto;
    text-align:center;
}
ul {
    display:flex;
    justify-content:space-around;
    align-items:center;
    margin:0 auto;
    flex-direction: column;
    width: 100%;
    padding:0;

    li {
        list-style-type:none;
        width:100%;
        height: 350px;
        margin-bottom:35px;
        border-radius: 2px;
        overflow:hidden;

        @media screen and (min-width: 768px) {
            width:30% !important;

        }
    }

    a {
        list-style-type:none;
        width:100%;
        height: 300px;
        margin-bottom:35px;
        border-radius: 2px;
        overflow:hidden;

        &:hover {
            text-decoration:none;
        }

        @media screen and (min-width: 768px) {
            width:30%;

        }

        .card-info-holder {
            background: #eee;
            text-align:left;
            padding:25px;
            height:100%;
            position: relative;
            padding-bottom: 0;

            &:hover {
                background: #3f444f;

                div {
                    h3 {
                        color: white;
                    }
                }
                h4 {
                    color: ${props => props.theme.colors.prime};
                }
                p {
                    color: white;
                    text-decoration: none;
                }
            }

            div {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 10px;
                position: relative;
                :before {
                    content:"";
                    height:1.5px;
                    width:100%;
                    left:-1%;
                    background:${props => props.theme.colors.prime};;;
                    position: absolute;
                    bottom: -6px;
                } 
                h3 {
                    color:#555;
                    font-size: 1.5rem;
                    margin:0;
                    padding-left: 10px;
                    position: relative;
                }
                span {
                    overflow: hidden;
                    display: inline-block;
                    position: relative;
                    color: white;
                    background: ${props => props.theme.colors.prime};;
                    width: 25px;
                    height: 25px;
                    font-size: 3rem;
                    border-radius: 2px;

                    svg {
                        color: white;
                        width: 20px;
                        height: 20px;
                        position: absolute;
                        margin: 0 auto;
                        left: 3px;
                        top: 3px;
                    }
                } 
            }

            h4 {
                text-transform: uppercase;
                font-size: .7rem;
                color:#222;

            }
            
            p {
                color: #222;
                font-size: .9rem;
                font-weight: 400;
                margin:0;
                text-align:left;
            }

            a {
                position: absolute;
                bottom: 10px;
                width: 100%;
                display: block;
                height: auto;
                font-size: .65rem;
                color: ${props => props.theme.colors.prime};;
                text-transform: uppercase;
                font-weight: bold;
            }
        }
        
    }
}

@media screen and (min-width: 768px) {

ul {
    flex-direction:row;
    width:100%;
    flex-wrap:wrap;
    text-align:center;
    
    li {
        width:25%;
        margin-bottom:0;

    }
}
}
`