import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import PaketPris from 'components/PaketPris'
import { FaTruckLoading, FaCcMastercard, FaWarehouse  } from "react-icons/fa";
import Image from "gatsby-image" 


import { ThreeColsHolder } from "./ThreeCols.styles" 

const TwoCols = (props) => ( 
    <ThreeColsHolder className={props.Class}> 
        <Container>
            <Row>
                <Col>
                    <h3><FaTruckLoading />Flytthjälp</h3>
                    <p>Vi på Flyttexpressen har en lång erfarenhet av allt inom flytthjälp. Det spelar ingen roll om det gäller flytt av en liten lägenhet, ett större kontor eller ett helt företag. Vi har specialutbildade packmästare, duktiga expressarbetare och skickliga chaufförer med lång erfarenhet. Dessutom använder vi det senaste vad gäller material och fordon.</p>
                </Col>
                <Col>
                    <h3>< FaCcMastercard />Betalsätt</h3>
                    <p>
                    Vi accepterar följande betalningsmetoder: Faktura, Swish, Kortbetalning.
                    Du kan välja att betala med med ditt Mastercard, Visa eller på 
                    faktura om du hellre vill det. Hör av dig till oss så berättar 
                    vi mer om våra betaltjänster.
                    </p>
                </Col>
                <Col>
                    <h3>< FaWarehouse />Magasinering</h3>
                    <p>Vi erbjuder magasinering av möbler i värmeförhållande. Nu kan vi på Flyttexpressen också erbjuda magasinering av möbler i varma förhållande. Känns det lockande att bevara era möbler på ett värdigt sätt så hör av er till oss. Vi på Flyttexpressen kan nu magasinera era möbler i värme, tryggt och i säkert förvar. Våra lokaler är fri från fukt och andra tråkigheter.</p>
                </Col>
            </Row>
        </Container> 
    </ThreeColsHolder>
)

export default TwoCols