import styled from "styled-components"

export const SeoTextHolder = styled.div`
background:#fff;
width:100%;
//margin:75px auto;
display:flex;
justify-content:center;
align-items:center;
flex-direction:column;
padding-top:75px;
padding-bottom: 75px;

h2 {
    font-size:1.4rem;
    color:#555;
    position:relative;
    text-align:center;
}
h3 {
    margin:0;
    color:#555;
    font-size: 1.4rem;
    text-align: center;
}
p {
    text-align:center;
    font-size:15px;
    color: #555;
    font-weight: 300;
    width: 80%;
    margin: 0 auto 35px;
}

.stadsdelar-rubrik {
}
.stadsdelar {
    background:${props => props.theme.colors.blue};
    padding:15px;
    li {
        list-style-type:none;
        color:white;
        margin:10px;

        a {
            color: white;
            transition: .3s;
            &:hover {
                color: ${props => props.theme.colors.orange};
            }
        }
    }
}

.seo-img-holder {
    display: flex;
    flex-direction: column;
    width: 90%;
    justify-content: space-around;
    align-items: center;

    @media screen and (min-width: 700px)  {
        width: 80%;
        flex-direction: row;

    }

    img {
        width: 90%;
        height: auto;
        @media screen and (min-width: 700px)  {
        width: 33%;
        }
    }
}


`
