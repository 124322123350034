import React from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron'
import Container from 'react-bootstrap/Container'
import { FaPhoneVolume } from 'react-icons/fa';



// Styles
import { HeroHolder } from "./Hero.styles"

import { useSiteConfigQuery } from 'Hooks/useSiteConfigQuery'


const Hero = () => {
    const siteConfig = useSiteConfigQuery()

    return (
        <HeroHolder>   
            <Jumbotron fluid style={{height:'100%', display: 'flex', alignItems:'center'}}>
                <Container>
                    <h1 className="trigger-hero">Johanssons Flyttexpress AB</h1>
                    <p>
                    TRYGGHET & KVALITÉ<br/>
                    Vi erbjuder företag, kommuner, myndigheter och privatpersoner <br/>flytt samt städ,
                    uppdrag och internationella transporter av bohag. Kontakta oss gärna på telefon för rådgivning.
                    </p>
                    <div class="row">
                    <a href="tel:031916633"><p>Göteborg<br/>031-91 66 33</p> <FaPhoneVolume/> </a>
                    <a href="tel:0101787833"><p>Jönköping<br/>010-178 78 33</p> <FaPhoneVolume/> </a>
                    <a href="tel:0737360029"><p>Jour<br/>073-736 00 29</p> <FaPhoneVolume/> </a>
                    </div>
                </Container>
            </Jumbotron>
        </HeroHolder>    
   )
}

export default Hero 
