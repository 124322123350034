import styled from "styled-components"

export const AccordionHolder = styled.div`

    .accordion {
        .card {
            background: ${props => props.theme.colors.prime};
            color: white;
            .card-header {
                background: #106790;
                padding: 0;
                button {
                    color: white;
                    width: 100%;
                    height: 100%;
                    text-align: left;
                    padding-left: 35px;
                    padding: 15px 35px;
                    background: #106790;
                    border: none;
                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
            .collapse {

                .card-body {
                    background: ${props => props.theme.colors.prime};
                    color: white;
                    text-align: left;
                }
            }
        }

    }

`