import { useStaticQuery, graphql } from 'gatsby';



export const useHyrbilDataQuery = () => {
    const data = useStaticQuery(graphql`
    query HyrbilQuery {
        markdownRemark(frontmatter: {type: {eq: "hyr"}}) {
            frontmatter {
            hyrbil {
                id 
                name
                link
                fa
                h4
                link
                title
                excerpt
                    }
                }
            }
        }
    `)
    return data.markdownRemark.frontmatter
}