import React from 'react'
import { Link } from 'gatsby' 
import { FaClipboardList } from "react-icons/fa";

// Styles 
import { SeperateHolder } from "./Separate.styles"

const Seperate = () => (
    <SeperateHolder>
        <div className="svg-holder">
            <FaClipboardList/>
        </div>
        <h4>Flytta tryggt med flyttexpressen</h4>
        <Link to="/kontakt">Kontakta oss</Link>

    </SeperateHolder>
)
export default Seperate