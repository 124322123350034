import styled from "styled-components"
import bg from "images/sep.jpg"

export const SeperateHolder = styled.div`
    width:100%;
    background:url('${bg}');
    background-size: cover;
    background-position: center;
    height:50vh;
    position: relative;

    &:after {
        background: black;
        opacity: .5;
        position: absolute;
        top:0;
        width: 100%;
        content: "";
        height: 100%;
    }

    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    position:relative;
    z-index: 1;

    .svg-holder {
        border-radius: 50%;
        background:${props => props.theme.colors.prime};;
        width: 75px;
        height: 75px;
        margin: 0 auto;
        text-align:center;
        position:relative;
        z-index: 2;

        svg {
            color: white;
            font-size: 3rem;
            top: 10px;
            position: relative;   
        }
    }

    h4 {
        color: white;
        font-size: 2rem;
        margin: 35px;
        text-align:center;
        position:relative;
        z-index: 2;
    }
    p {
        color:black;
        font-size:1.5rem;
        margin:0;
        margin-bottom:35px;
        text-align:center;
        position:relative;
        z-index: 2;
    }
    a {
        background: ${props => props.theme.colors.prime};
        color: white;
        transition:.3s;
        padding:7px 15px;
        border: 1px solid ${props => props.theme.colors.prime};
        font-family:'Nunito', sans-serif;
        text-transform:uppercase;
        text-align:center;
        border-radius:50px;
        height: 50px;
        width: 70%;
        line-height: 45px;
        position:relative;
        z-index: 2;

        :hover {
            background:transparent;
            border:1px solid white;
            color:white;
            text-decoration:none;
        }
    }

    @media screen and (min-width: 768px) {
    
        a {
            height:auto;
            width:30%;
            padding:0;
        }
    }
`