import React from "react"
import { graphql, Link } from 'gatsby'
import van from "images/van.png"
import iff from "images/if.png"
import skydd from "images/skyd.png"
import piano from "images/piano.png"
import 'bootstrap/dist/css/bootstrap.min.css';
import { BsPersonFill, BsPeopleFill } from "react-icons/bs";
import { Container, Row, Col } from 'react-bootstrap'
import AccordionSmall from 'components/AccordionSmall'




// Components
import Layout from 'components/Layout'
import SEO from "components/SEO"
import Hero from "components/Hero"
import Cardsbox from "components/Cardsbox"
import Cards from "components/Cards"
import SeoText from "components/SeoText"
import Seperate from "components/Seperate"
import TwoBox from "components/TwoBox"
import TwoBoxIcons from "components/TwoBoxIcons"
import CarouselComponent from "components/Carousel"
import TwoCols from "components/TwoCols"
import ThreeCols from "components/ThreeCols"

import PaketPris from 'components/PaketPris'
import AccordionComponent from 'components/Accordion'



const IndexPage = ({data}) => {
// const posts = data.allMarkdownRemark.edges; 
console.log(data)


return(

  <Layout>
    <SEO title="Flyttfirma Göteborg som utför alla flyttjänster"/>
      <Hero />
      {/* <Cardsbox /> */}
      <SeoText/>
      <Cards />
      <Seperate />  

      {/* <TwoCols 
        Class="homepage-paket"
        Header="Flyttfirmans priser & flyttpaket"
        Content="Det kan ibland vara svårt att veta exakt vad man behöver från flyttfirman när man ska boka flytthjälp.
        För att göra det hela lite lättare har vår flyttfirma Malmö skapat flera billiga flyttpaket som du kan välja mellan."
        HeaderLeft="Flyttpaket – Privat"
        HeaderRight="Flyttpaket – Företag"
        IconLeft={
          <>
          <BsPersonFill/>
          </>
        }
        IconRight={
          <>
          <BsPeopleFill />
          </>
        }
        ContentLeft={
        <>
          <PaketPris 
          pris='2300kr'
          header='PRIVATPAKET – LILLA'
          description='Med lilla flyttpaketet ingår 2 man á 4 timmar med lastbil upp till 22m³. Avbokningsskydd ingår.'
          />
          <PaketPris 
              pris='3300kr'
              header='PRIVATPAKET – MELLAN'
              description='Med mellan flyttpaketet ingår 3 man á 4 timmar med lastbil upp till 22m³. Avbokningsskydd ingår.'
          />
          <PaketPris 
              pris='4600kr'
              header='PRIVATPAKET – STORA'
              description='Med stora flyttpaketet ingår 4 man á 4 timmar med två lastbilar upp till 22m³ vardera. Avbokningsskydd ingår.'
          />
        </>
        }
        ContentRight={
          <>
            <PaketPris 
              pris="3800kr"
              header="FÖRETAGSPAKET – LILLA"
              description="Med lilla flyttpaketet ingår 2 man á 4 timmar med lastbil upp till 22m³. Avbokningsskydd ingår."
              />
            <PaketPris 
                  pris="5700kr"
                  header="FÖRETAGSPAKET – MELLAN"
                  description="Med mellan flyttpaketet ingår 3 man á 4 timmar lastbil upp till 22m³. Avbokningsskydd ingår."
              />
            <PaketPris 
                  pris="7600kr"
                  header="FÖRETAGSPAKET – STORA"
                  description="Med stora flyttpaketet ingår 4 man á 4 timmar med två lastbilar upp till 22m³ vardera. Avbokningsskydd ingår."
              />
          </>
        }
      /> */}

      <ThreeCols />

      {/* <Container style={{padding: "70px 0px"}}>
        <Row>
          <Col>
            <h3 style={{color: "#555", textAlign: "center", marginBottom: "35px"}}>Bra att veta inför din flytt</h3>
             <AccordionSmall /> 
            <Link to="/svar" style={{textAlign: "center", display: "block", margin: "15px auto", color: "#555"}}>Läs mer... </Link>
          </Col>
        </Row>
      </Container> */}
  
      <TwoCols 
        Class="trygghet-cols"
        ConentClass="trygghet-content"
        ContentLeftHeader="Trygghet och ansvar"
        ContentRightHeader="Vi erbjuder avbokningsskydd"
        ContentLeft="Du ska alltid känna dig trygg med oss Flyttexpressen, 
        därför är vi bl.a. försäkrade liksom arbetar enligt konsumentverket samt
         Sveriges Åkeriföretags allmänna bestämmelser i enlighet med Bohag 2010 
         för privatkunder och Kontor 2003 för företagskunder. Vi är varsamma och 
         värnar ständigt om dina tillhörigheter, men ibland händer ändå olyckor 
         och då tar vi givetvis vårt ansvar. Vi är dessutom helt ansvarsförsäkrade via If Försäkringar."
        ContentRight="Ibland blir det inte riktigt som du hade tänkt och det har vi full förståelse för. 
        Det kan t.ex. hända att du behöver ändra dagen för flyttningen eller att du inte behöver någon 
        flyttfirma i Göte och vill avboka din flytthjälp. Vi erbjuder avbokningsskydd på alla tjänster 
        som du bokar genom oss. Du behöver aldrig ge oss någon särskild anledning till varför 
        en order blir avbokad. Vi önskar dock att du meddelar oss senast 2 dagar innan flyttningen."
        ImgRight={skydd}
        ImgLeft={iff}
      />
  </Layout>
)
  
}

export default IndexPage

export const indexQuery = graphql` 
  query blogListQuery {
  allMarkdownRemark(
    filter: {frontmatter: {type: {eq: "post"},published: {eq: true}}} 
    sort: {fields: frontmatter___date, order: DESC}
    ) {
    edges {
      node {
        fields {
          slug
          readingTime {
            text
          }
        }
        frontmatter {
          date
          title
          slug
          image {
                childImageSharp{
                    fluid(maxWidth:200){
                        ...GatsbyImageSharpFluid
                    }
                }
            }        
        }
        excerpt
      }
    }
  }
}
`
