import styled from "styled-components"
import hero from "images/hero-black.jpg"


export const HeroHolder = styled.div`
height: 100vh;
width: 100%;
position: relative;
background: url('${hero}');
background-size: cover;
background-position: top;

@media screen and (min-width: 1600px) {
height: 60vh;
}


.jumbotron {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    color: white;

    .container {
        height: 30vh;

        a {
            background: ${props => props.theme.colors.prime};
            color: white;
            padding: 10px 20px;
            border-radius: 7px;
            border: 1px solid ${props => props.theme.colors.prime};
            transition: .3s;
            display: block;
            width: 300px;
            position: relative;
            margin: 5px;

            p {
                display: inline-block;
                margin:0;
                font-weight: bold;
                font-size: 1.5rem;
            }

            svg {
                transform: rotate(-45deg);
                font-size: 2.5rem;
                top: -15px;
                position: relative;
                margin-left: 15px;
            }

            &:hover {
                text-decoration: none;
                background: transparent;
                border: 1px solid white;
                
            }
        }
    }
}
`
