import React from 'react'
import packett from 'images/packett.jpg'
import packtva from 'images/packtva.jpg'
import packtre from 'images/packtre.jpg'


// Styles 
import { SeoTextHolder } from "./SeoText.styles"

const SeoText = () => (  
    <SeoTextHolder>
        <h2>Johanssons Flyttexpress AB</h2>
        <p>
        Med 13 års erfarenhet som flyttfirma är vi experter på snabba och 
        säkra bohagsflyttar. 7 av 10 flyttar som vi gör kommer från 
        kundrekommendationer eller återkommande klienter, som vet att vi 
        sköter hela flyttprocessen smärtfritt och att vår service håller högsta nivå. 
        Vår långa erfarenhet gör också att vi arbetar effektivare – och kan ge dig ett lägre pris.
        </p>

        <h3>Vi ordnar flytthjälp som täcker dina behov</h3>
        <p>Vi kan glatt meddela att du nu kan pusta ut, eftersom 
            Flyttexpressen kan erbjuda dig flytthjälp med 
            varje steg i processen – vi vågar lova att vi täcker 
            samtliga av era flyttbehov.<br/>
            Ta en titt på våra tjänster via knappen nedanför.</p>
        
        <div className="seo-img-holder">
            <img src={packett} />
            <img src={packtva} />
            <img src={packtre} />
        </div>
        
    </SeoTextHolder>
 
)

export default SeoText
