import styled from "styled-components"

export const CarouselWrapper = styled.div`
width: 80%;
margin: 0 auto;
display: none;

@media screen and (min-width: 1025px) {
display: none;
}

.carousel {

    .carousel-indicators {
        right: 0%;
        left: 50%;
        bottom: -15px;

        li {
            background-color: white;
            height: 20px;
            width: 20px;
            border-radius: 50%;
        }
    }

    .carousel-item {
        position: relative;
        background: #3f444f;
        
        .overlay {
            position:absolute;
            background: rgba(0,0,0,0);
            width: 100%;
            height: 100vh;
            z-index:2;
            display: none;
        }
        img {
            height: 300px;
            width: 50%;
            transition: 7s;
            display: inline-block;
        }
        .carousel-caption {
            z-index:3;
            color: white;
            width: 50%;
            position: relative;
            right: 0;
            left: 0;
            bottom: 0;
            display: inline-block;
            height: 100%;
            padding:0;

            h3 {
                margin: 0 auto;
                margin-bottom: 15px;
                width: 80%;
            }
            p {
                margin: 0 auto;
                width: 80%;
                font-size: .9rem;
            }
        }
    }

    .carousel-control-next {
        z-index: 99;
    }
    carousel-control-prev {
        z-indexx: 99;
    }
}
`