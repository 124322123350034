import styled from "styled-components"

export const ThreeColsHolder = styled.div`

.container {
    .row {
        .col {
            padding: 30px 40px;
            h3 {
                font-size: 1.3rem;
                text-slign: left;
                color: #333;
                margin:0;

                svg {
                    color: ${props => props.theme.colors.prime};
                    margin-right: 15px;
                }

                
            }
            p {
                font-size: 15px;
                color: #333;
                line-height: 1.6;
                font-weight: 300;
                text-slign: left;
            }

            &:first-child{
                background: #fafafa;
            }
            &:nth-child(){
                background: #f5f5f5;
            }
            &:last-child{
                background: #f0f0f0;
            }
        }
    }
}
 
`
