import styled from "styled-components"
import redbox from 'images/red-box.jpg'

export const TwoBoxIconHolder = styled.div`
display:flex;
flex-direction:column; 
width:100%;
max-width: 1600px;
background: #ad1d1d;
background:linear-gradient(to right, rgba(173, 29, 29,.9),rgba(173, 29, 29,.7)),url(${redbox});
background-size: cover;
background-position: center;
margin: 0;
position:relative;
z-index: 1;

@media screen and (min-width: 768px) {
    width:calc(100% - 75px);
    margin: 0 auto;
}

    > div {
        width: 100%;
        text-align: left;
        padding: 15px;
        margin: 0 auto; 
        text-align: center;
        


        .svg-holder {
            border-radius: 50%;
            padding: 30px;
            background:red;
            width: 100px;
            height: 100px;
            margin: 0 auto;

            svg {
                color: white;
                font-size: 5rem;
                
            }
        }
        

        h3 {
            margin-bottom:35px; 
            position:relative;
            font-size:1.7rem;
            color: white;
            :before {
                content:"";
                height:2px; 
                width:50%;
                left:25%;
                background:white;
                position: absolute;
                bottom: -5px;
            }
        }
        p {
            font-size:1.2rem;
            line-height:1.5;
            color: white;
            width: 90%;
            margin: 0 auto;
        }

        a {
            background: white;
            color: red;
            padding: 10px 35px;
            border-radius: 30px;
            transition: .3s;
            border: 1px solid white;
            display: block;
            width: 70%;
            margin: 15px auto;

            &:hover {
                background: transparent;
                color: white; 
            }
        }
    }

@media screen and (min-width: 768px) {

    flex-direction:row;
    padding: 35px;

    > div {
        width:100%;

        div {
            width: 50%;
        }

        .rightbox {
            text-align: center;

            img {
                max-width: 400px;
            }
        }
        
        h2 {
            font-size: 2rem;
            color:#ad1d1d;
            :before {
                
                width:50%;
                left:0%;
                
            }
        }
        p {
            font-size:.9rem;
            line-height:1.5;
        }
    }
}
`
export const Contianer = styled.div`
  max-width: 1140px;
  margin: 0 auto;
  padding: 35px 0 !important;

  div {
    height: auto;
    padding-bottom: 70px;
        
    @media screen and (min-width: 768px) {
        height: auto;
    }
  }

  @media screen and (min-width: 768px) {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  `